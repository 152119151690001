import React from "react";
import "./CSResearch.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const CSResearch = ({
  title,
  secondaryTitle,
  description = [],
  iframeurl,
  img,
  alt,
  imgTwo,
  altTwo,
  summary = [],
  summaryTitle,
}) => {
  return (
    <div>
      <div className="case-study-content">
        {title && <h2 className="cs-title">{title}</h2>}
        {secondaryTitle && <h4 className="cs-subtitle">{secondaryTitle}</h4>}

        {description.map((description, index) => (
          <p
            className="cs-description"
            key={index}
            dangerouslySetInnerHTML={{
              __html: `${description}`,
            }}
          ></p>
        ))}
      </div>
      {img && (
        <Zoom>
          <img
            className="cs-contentimg"
            src={img}
            alt={alt || "Research image"}
            onContextMenu={(e) => e.preventDefault()}
          />
        </Zoom>
      )}
      <div>
        <div>
          {" "}
          {iframeurl && (
            <div className="cs-iframe-container">
              <img
                className="cs-iframe-annotate"
                src="https://storage.googleapis.com/dv-portfolio-assets/zoom%20instruction.svg"
                alt=""
              />

              <iframe
                className="cs-iframe"
                src={iframeurl}
                title={`${title || "Research"} content`}
              />
            </div>
          )}
          <div className="cs-whitestrip"></div>
        </div>

        <img src={imgTwo} alt={altTwo} />

        {Array.isArray(summary) && summary.length > 0 && (
          <div className="cs-summary case-study-content">
            <h4 className="cs-subtitle">{summaryTitle}</h4>
            <ul>
              {summary.map((summary, index) => (
                <li
                  className="cs-li"
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: `${summary}`,
                  }}
                ></li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CSResearch;
