import React from "react";
import Navigation from "./../../components/Navigation/Navigation";
import CurrentWork from "../../components/CurrentWork/CurrentWork";
import Otherwork from "../../components/OtherWork/Otherwork";
import Footer from "./../../components/Footer/Footer";
import TopGradient from "../../components/TopGradient/TopGradient";

function Work() {
  return (
    <div>
      <TopGradient />
      <Navigation></Navigation>
      <CurrentWork></CurrentWork>
      <Otherwork></Otherwork>
      <Footer> </Footer>
    </div>
  );
}

export default Work;
