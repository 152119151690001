const heroImages = {
  img_1: "https://storage.googleapis.com/dv-portfolio-assets/hero/Hero1.webp",
  img_2: "https://storage.googleapis.com/dv-portfolio-assets/hero/Hero2.webp",
  img_3: "https://storage.googleapis.com/dv-portfolio-assets/hero/Hero3.webp",
  img_4: "https://storage.googleapis.com/dv-portfolio-assets/hero/Hero4.webp",
  img_5: "https://storage.googleapis.com/dv-portfolio-assets/hero/Hero5.webp",
  img_6: "https://storage.googleapis.com/dv-portfolio-assets/hero/Hero6.webp",
  img_7: "https://storage.googleapis.com/dv-portfolio-assets/hero/Hero7.webp",
  img_8: "https://storage.googleapis.com/dv-portfolio-assets/hero/Hero8.webp",
};
module.exports = heroImages;
