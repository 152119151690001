import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "./Slider.css";

const ImageSlider = () => {
  const sliderRef = useRef(null);
  const images = [
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/1.png",
      alt: "Coastal cliff sunset",
    },
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/2.png",
      alt: "Waterfall in mountains",
    },
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/3.png",
      alt: "Mountain lake reflection",
    },
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/4.png",
      alt: "Bowl of pasta",
    },
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/5.png",
      alt: "Tropical coastline",
    },
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/6.png",
      alt: "Tropical coastline",
    },
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/7.png",
      alt: "Tropical coastline",
    },
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/8.png",
      alt: "Tropical coastline",
    },
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/9.png",
      alt: "Tropical coastline",
    },
    {
      url: "https://storage.googleapis.com/dv-portfolio-assets/aboutphotos/10.png",
      alt: "Tropical coastline",
    },
  ];
  useEffect(() => {
    // Force autoplay to start
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 4,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
    swipe: true,
    adaptiveHeight: true,
    initialSlide: 2,
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-wrapper">
      <div className="slider-header">
        <h2 className="slider-title">Other than work</h2>
        <p className="slider-description">
          Beyond design, I have a deep love for exploration and movement. I
          enjoy playing sports—badminton these days, though football was my
          first love. I also find inspiration in art, frequently visiting
          galleries to immerse myself in creativity. Traveling to new places
          fuels my curiosity, and recently, I’ve been prioritizing health and
          fitness, striving to build a balanced and active lifestyle.
        </p>
      </div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slide">
            <div className="slide-content">
              <img src={image.url} alt={image.alt} className="slide-image" />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
