import React from "react";
import OldProjectCollection from "./components/OldProjectCollection/OldProjectCollection";
import Navigation from "./../../components/Navigation/Navigation";
import TopGradient from "../../components/TopGradient/TopGradient";
import Footer from "./../../components/Footer/Footer";

const OldProjects = () => {
  // Multiple projects data
  const projectsData = [
    {
      title: "Freelancing, building products & Working with Startups ",
      description:
        "Pre-COVID, developed a football training product that, unfortunately, couldn't sustain beyond three months of the pandemic. During COVID, collaborated with startups, freelancing and co-developing early-stage ideas. Designed, developed, and worked on  multiple ideas, helping new businesses start their journey..",
      caption: "Product Design, Web Devlopment - 2020–2021",
      columns: 2,
      images: [
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/moxie1.webp",
          alt: "Product for football training",
          overlay: "Product for football training",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/freelance2.webp",
          alt: "Product for rapid quotations",
          overlay: "Product for rapid quotations  ",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/freelance5.png",
          alt: "UI for Ventilator",
          overlay: "UI for Ventilator",
        },

        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/freelance8.png",
          alt: "UI design for automation tools",
          overlay: "Landing page for Cloud Consultanting",
        },

        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/freelance9.png",
          alt: "UI design for automation tools",
          overlay: "App Design for COVID certificate validation ",
        },
        // {
        //   url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/freelance4.webp",
        //   alt: "UI design for automation tools",
        //   overlay: "App Design for NGO ",
        // },
      ],
    },
    {
      title: "Solving Problems as a Solo Designer at Cadsol",
      description:
        "Designed a UI for automating complex engineering drawings. Contributed by creating instructional videos and booklets to enhance user engagement.",
      caption: "Fullstack Design - 2019–2020",
      columns: 2,
      images: [
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/cadsol%201.png",
          alt: "UI design for automation tools",
          overlay: "Product Configurators",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/cadsol%202.png",
          alt: "UI design for automation tools",
          overlay: "Configurator for 3d MCC Enclosure drawings ",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/cadsol%206.webp",
          alt: "UI design for automation tools",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/cadsol%207.webp",
          alt: "UI design for automation tools",
          overlay: "Instructional video ",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/cadsol%205.JPG",
          alt: "UI design for automation tools",
          overlay: "Wireframes for  boiler configurator",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/cadsol%204.webp",
          alt: "UI design for automation tools",
          overlay: "Configurator UX for boilder 3d drawings ",
        },
      ],
    },
    {
      title: "End-to-End Designs for Sporting Group",
      description:
        "Developed visually engaging pitch decks to secure sponsorship deals for Groups SGI India Operations. Built websites to promote academies and tournaments. Crafted social media posts featuring top players like Rahul Bheke and Jayesh Rane.",
      caption: "WEB & VISUAL DESIGN - 2018–2021",
      columns: 2,
      images: [
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/sgi1.png",
          alt: "Sponsorship pitch deck designs",
          overlay: "Pitch Deck Design",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/sgi2.png",
          alt: "Website for sports academies",
          overlay: "Academy Website",
        },
        // {
        //   url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/sgi3.webp",
        //   alt: "Website for sports academies",
        //   overlay: "Visual Design direction for SGI ",
        // },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/sgi4.png",
          alt: "Website for sports academies",
          overlay: "SGI International website",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/sgi5.png",
          alt: "Website for sports academies",
          overlay: "Social Media Posts",
        },
      ],
    },
    {
      title: "Creating Brand Identities and helping companies to kickstart",
      description:
        "Embracing new challenges, I collaborated with companies across diverse industries, crafting compelling graphics to help them launch and establish their presence. Working closely with founders at the inception of their businesses, I contributed to shaping their brand identity—from defining the right tone to setting the visual direction for their public presence.",
      caption: "Visual DESIGN - 2018-2021",
      columns: 2,
      images: [
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/02.gif",
          alt: "Website redesign for Sporting Group International",
          overlay: "Brand design for lampshade brand",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/nutrivise-thumb.png",
          alt: "Modern sports branding",
          overlay: "A fresh new look",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/emkon-thumb.png",
          alt: "Modern sports branding",
          overlay: "Logo design for supply chain brand ",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/ypl.jpg",
          alt: "Modern sports branding",
          overlay: "Identity design for football league",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/cfci-thumb.png",
          alt: "Modern sports branding",
          overlay: "Jersey design for youth I-league club",
        },
      ],
    },

    {
      title: "Working with Agencies & early career designing",
      description:
        " As a self-taught  designer, I honed my skills by working with agencies and creating impactful solutions for businesses of all sizes—from local shops to global IT firms. My early career focused on packaging design, brand activations, and social media campaigns, ensuring each project delivered meaningful engagement and strong visual identity",
      caption: "Graphic Design & Packeging - 2016-2018",
      columns: 2,
      images: [
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/ams-thumb.png",
          alt: "Professional identity branding for Emkon",
          overlay: "Prouct Catalogue for leather brand",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/blutec-thumb-02.png",
          alt: "Custom business templates",
          overlay: "Packaging Design for Engine Oil Company",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/techdata-thumb.png",
          alt: "Professional identity branding for Emkon",
          overlay: "Quiz game design for IT firm",
        },
        {
          url: "https://storage.googleapis.com/dv-portfolio-assets/oldwork/x-fitt-thumb.png",
          alt: "Professional identity branding for Emkon",
          overlay: "Social Media campaign for fitness brand",
        },
      ],
    },

    // {
    //   title: "Helping Sporko Create a Unified Identity",
    //   description:
    //     "Designed jerseys and managed operations for the youth I-League team. Directed branding and managed operations for Ahmedabad Defenders in the national volleyball league.",
    //   caption: "VISUAL DESIGN - 2017–2019",
    //   columns: 2,
    //   images: [
    //     {
    //       url: "https://unsplash.com/photos/9",
    //       alt: "Custom jerseys for Youth I-League",
    //       overlay: "Youth League Jerseys",
    //     },
    //     {
    //       url: "https://unsplash.com/photos/10",
    //       alt: "Sports branding for volleyball team",
    //       overlay: "Ahmedabad Defenders Branding",
    //     },
    //   ],
    // },
    // {
    //   title: "Designing for Passionate Cricket Players",
    //   description:
    //     "Created a logo symbolizing new beginnings for specially-abled cricketers. Designed engaging Instagram posts to showcase players and match updates.",
    //   caption: "VISUAL DESIGN - 2021",
    //   columns: 2,
    //   images: [
    //     {
    //       url: "https://unsplash.com/photos/11",
    //       alt: "Logo design for inclusive cricket team",
    //       overlay: "Inclusive Cricket Logo",
    //     },
    //     {
    //       url: "https://unsplash.com/photos/12",
    //       alt: "Social media post featuring cricket players",
    //       overlay: "Player Highlights",
    //     },
    //   ],
    // },
  ];

  return (
    <div className="portfolio-container">
      <header className="portfolio-header"></header>
      <Navigation></Navigation>
      <TopGradient></TopGradient>

      <OldProjectCollection projects={projectsData} />
      <Footer />
    </div>
  );
};

export default OldProjects;
