// ScatteredPolaroids.jsx
import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import "./ScatteredPolaroids.css";
import Zoom from "react-medium-image-zoom";
import PropTypes from "prop-types";
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("./images", false, /\.(png|jpe?g|svg)$/)
);
console.log(images);

const descriptions = [
  "Description for image 1",
  "Description for image 2",
  "Description for image 3",
  // Add more descriptions as needed
];

const ScatteredPolaroids = ({
  keepScattered = false,
  polaroidsToShow = null,
}) => {
  const [allPhotos] = useState(
    images.map((image, index) => {
      const imageName = image.split("/").pop().split(".")[0]; // Extract file name without extension
      return {
        id: index + 1,
        url: image,
        title: imageName,
        description: descriptions[index] || `Description for ${imageName}`,
      };
    })
  );
  const [photos] = useState(() => {
    if (!polaroidsToShow || polaroidsToShow >= allPhotos.length) {
      return allPhotos;
    }

    return allPhotos.slice(0, polaroidsToShow);
  });
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const controls = useAnimation();
  const [isVisible, setIsVisible] = useState(false);

  const getRandomPosition = () => ({
    x: Math.random() * 10, // Increased range for more scatter
    y: Math.random() * 10,
    rotate: Math.random() * 8, // Increased rotation range
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          void controls.start("visible");
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, [controls, keepScattered]);

  const containerVariants = {
    initial: {
      transition: {
        staggerChildren: 0.1,
      },
    },
    visible: keepScattered
      ? () => ({
          ...getRandomPosition(),

          // transition: {
          //   type: "spring",

          //   stiffness: 50,

          //   damping: 10,
          // },
        })
      : {
          x: 0,

          y: 0,

          rotate: 0,

          opacity: 1,

          transition: {
            type: "spring",

            stiffness: 50,

            damping: 10,
          },
        },
  };

  const photoVariants = {
    initial: () => ({
      ...getRandomPosition(),
      opacity: 1,
    }),
    visible: keepScattered
      ? () => ({
          ...getRandomPosition(),
          opacity: 1,
          transition: {
            type: "spring",
            stiffness: 50,
            damping: 10,
          },
        })
      : {
          x: 0,
          y: 0,
          rotate: 0,
          opacity: 1,
          transition: {
            type: "spring",
            stiffness: 50,
            damping: 10,
          },
        },
  };
  return (
    <div ref={containerRef} className="polaroids-container">
      <motion.div
        variants={containerVariants}
        initial="initial" // Changed from 'hidden' to 'initial'
        animate={controls}
        className="polaroids-grid"
      >
        {photos.map((photo) => (
          <motion.div
            key={photo.id}
            className={`polaroid-wrapper ${isDragging ? "dragging" : ""}`}
            variants={photoVariants}
            custom={photo.id}
            drag
            dragConstraints={{
              top: -100,
              left: -100,
              right: 100,
              bottom: 100,
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onDragStart={() => setIsDragging(true)}
            onDragEnd={() => setIsDragging(false)}
          >
            <div className="polaroid">
              {/* Front of polaroid */}
              <div className="polaroid-front">
                <Zoom>
                  <img src={photo.url} alt={photo.title} />
                </Zoom>
                <h3>{photo.title}</h3>
              </div>

              {/* Back of polaroid */}
              <div className="polaroid-back">
                <p>{photo.description}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

ScatteredPolaroids.propTypes = {
  keepScattered: PropTypes.bool,
  polaroidsToShow: PropTypes.number,
};

export default ScatteredPolaroids;
