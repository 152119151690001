import { useCookies } from "react-cookie";

const useCookieValidator = () => {
  const [cookies] = useCookies(["config"]);

  const validator = (value) => {
    // console.log("Stored cookied:", cookies.config);
    if (cookies.config !== undefined && cookies.config === value) {
      // console.log("User is logged in");
      return true;
    }
    // console.log("Invalid user session");
    return false;
  };

  return [validator];
};

export default useCookieValidator;
