import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Outlet, useNavigate } from "react-router-dom";
import useCookieValidator from "../Auth/useCookieValidator";
const passwordHash = process.env.REACT_APP_PASSWORD_HASH;

const ProtectedRoute = () => {
  const [validator] = useCookieValidator();
  const [cookies] = useCookies(["config"]);
  const navigate = useNavigate();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const valdiateSessionCoookie = () => {
    if (validator(passwordHash)) {
      setIsUserLoggedIn(true);
    } else {
      navigate("/home/authFail");
    }
  };
  useEffect(() => {
    valdiateSessionCoookie();
    setIsDataLoaded(true);
  }, []);
  if (isDataLoaded) {
    if (isUserLoggedIn) {
      return <Outlet />;
    }
  }
  return null;
};

export default ProtectedRoute;
