import React, { useEffect, useState } from "react";
import "./OtherWork.css";
import _ from "lodash";
import {
  Navigate,
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";

import { useNavigate } from "react-router-dom";
import IMG_1 from "./assets/img-1.png";
import IMG_2 from "./assets/img-2.png";
import IMG_3 from "./assets/img-3.png";
import IMG_4 from "./assets/img-4.png";
import IMG_5 from "./assets/img-5.png";
import IMG_6 from "./assets/img-6.png";
import IMG_7 from "./assets/img-7.png";
import IMG_8 from "./assets/img-8.png";
import IMG_9 from "./assets/img-9.png";
import IMG_10 from "./assets/img-10.png";
import IMG_11 from "./assets/img-11.png";
import IMG_12 from "./assets/img-12.png";
import IMG_13 from "./assets/img-13.png";
import IMG_14 from "./assets/img-14.png";
import IMG_15 from "./assets/img-15.png";
import IMG_16 from "./assets/img-16.png";
import IMG_17 from "./assets/img-17.png";
import IMG_18 from "./assets/img-18.png";
import ImageCard from "./components/ImageCard/ImageCard";
import useInterval from "../../customHooks/useInterval/useInterval";
import Button from "./../Button/Button";
const allImages = [
  IMG_1,
  IMG_2,
  IMG_3,
  IMG_4,
  IMG_5,
  IMG_6,
  IMG_7,
  IMG_8,
  IMG_9,
  IMG_10,
  IMG_11,
  IMG_12,
  IMG_13,
  IMG_14,
  IMG_15,
  IMG_16,
  IMG_17,
  IMG_18,
];
const Otherwork = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);

  const handleImageShuffle = () => {
    setImages(_.sampleSize(allImages, 9));
  };

  useInterval(() => {
    handleImageShuffle();
  }, 2000);

  return (
    <div className="Otherwork">
      <div className="Otherwork-container">
        {images.map((image, key) => {
          return <ImageCard key={key} image={image} />;
        })}
      </div>

      <div className="Otherwork-action">
        {" "}
        <Button
          ctaText="View Archive"
          action={() => navigate("/work/old")}
        ></Button>
      </div>
    </div>
  );
};

export default Otherwork;
