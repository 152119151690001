import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NotificationSystem = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const CustomToastWithLink = () => (
    <div>
      <h4 className="cs-subtitle">Access Denied or auth TimedOut.</h4>
      <button
        onClick={() => window.open("https://www.linkedin.com/login", "_blank")}
        className="bg-blue-600 text-white px-4 py-1 rounded hover:bg-blue-700 transition-colors"
      >
        Connect with LinkedIn
      </button>
    </div>
  );

  useEffect(() => {
    if (location.pathname === "/home/authFail") {
      toast.error("Auth Timedout or Access Denied", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/home");
    }
    if (location.pathname === "/work/authPass") {
      navigate("/work/casestudies");
      toast.success("Authentication sucessful.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [location, navigate]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={false}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export default NotificationSystem;
