import React from "react";
import gradient from "./gradient.svg";
import "./TopGradient.css";

const TopGradient = () => {
  return (
    <div>
      <img className="gradient" src={gradient} alt="" />
    </div>
  );
};

export default TopGradient;
