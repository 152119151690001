import React from "react";
import "./CSOverview.css";
const CSOverview = ({ title, secondaryTitle, description }) => {
  return (
    <div className="case-study-content">
      <h2 className="cs-title"> {title}</h2>
      <h4 className="cs-subtitle"> {secondaryTitle}</h4>
      {description.map((description, index) => (
        <p className="cs-description" key={index}>
          {description}
        </p>
      ))}
    </div>
  );
};

export default CSOverview;
