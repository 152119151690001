const data = [
  {
    title: "Moxie",
    image: "import image",
    meta: {
      work: "Ui/UX",
      date: "2020",
      status: "Stopped",
    },
    description: "description goes here",
    slides: [
      {
        image: require("./images/img-3.png"),
        title: "Image title 1",
        description:
          "Description for image goes here. Description for image goes here. Description for image goes here. Description for image goes here",
        link: "",
      },
      {
        image: require("./images/img-3.png"),
        title: "Image title 2",
        description:
          "Description for image goes here. Description for image goes here. Description for image goes here. Description for image goes here",
        link: "",
      },
      {
        image: require("./images/img-3.png"),
        title: "Image title 3",
        description:
          "Description for image goes here. Description for image goes here. Description for image goes here. Description for image goes here",
        link: "",
      },
      {
        image: require("./images/img-3.png"),
        title: "Image title 4",
        description:
          "Description for image goes here. Description for image goes here. Description for image goes here. Description for image goes here",
        link: "",
      },
    ],
  },
  {
    title: "Embed UI",
    image: "import image",
    meta: {
      work: "UI/UX, ",
      date: "2020",
      status: "On-going",
    },
    description:
      "EmbedUI is an open source Project for enthusiasts, to speed-up & visualize UI development using Embedded Hardware on their PC/Laptop screens.",
    slides: [
      {
        image: require("./images/img-3.png"),
        title: "Building for Embeded Devices",
        description:
          "Making UI for embedded devices has an external LCD dependency, we solved this by using LCD as PC/Laptop screen  ",
        link: "",
      },
      {
        image: require("./images/img-3.png"),
        title: "Designing Electron App",
        description:
          "Description for image goes here. Description for image goes here. Description for image goes here. Description for image goes here",
        link: "",
      },
      {
        image: require("./images/img-3.png"),
        title: "Solving Onboarding with docs",
        description:
          "Description for image goes here. Description for image goes here. Description for image goes here. Description for image goes here",
        link: "",
      },
      {
        image: require("./images/img-3.png"),
        title: "Solving Onboarding with docs",
        description:
          "Description for image goes here. Description for image goes here. Description for image goes here. Description for image goes here",
        link: "",
      },
    ],
  },
];
export default data;
