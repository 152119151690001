import React, { useState, useEffect } from "react";
import "./pg.css";
import polo from "./assets/polo1.jpg";

const PolaroidGallery = () => {
  // Track mouse position
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // Update mouse position on move
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  // Calculate transform values based on mouse position
  const getTransform = (index) => {
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    // Calculate distance from center (normalized)
    const deltaX = (mousePosition.x - centerX) / centerX;
    const deltaY = (mousePosition.y - centerY) / centerY;

    // Different rotation for each image
    const baseRotation = index * 5 - 7.5;
    const dynamicRotation = baseRotation + deltaX * 5;

    // Different movement ranges for each image
    const translateX = deltaX * (10 + index * 5);
    const translateY = deltaY * (10 + index * 5);

    return `rotate(${dynamicRotation}deg) translate(${translateX}px, ${translateY}px)`;
  };

  // Image data with positions
  const images = [
    { src: polo, alt: "Polaroid 1" },
    // { src: "/api/placeholder/280/320", alt: "Polaroid 2" },
    // { src: "/api/placeholder/280/320", alt: "Polaroid 3" },
    // { src: "/api/placeholder/280/320", alt: "Polaroid 4" },
  ];

  return (
    <div className="">
      {images.map((image, index) => (
        <div
          key={index}
          className={`polaroid-about polaroid-${index + 1}`}
          style={{ transform: getTransform(index) }}
        >
          <div className="polaroid-content">
            <img src={image.src} alt={image.alt} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PolaroidGallery;
