import React from "react";
import "./AboutHero.css";
import PolaroidGallery from "../PoloroidGallery/PoloroidGallery";
import TestimonialGrid from "../../../../components/TestimonialGrid/TestimonialGrid";

const AboutHero = () => {
  return (
    <div className="abhero">
      <div className="abhero-container">
        <PolaroidGallery />
        <h1 className="abhero-title">
          {" "}
          Hello, I am Devednya
          <p className="abhero-description">
            {" "}
            I’m a product designer from India, currently based in Mumbai.
            <br></br> As a self-taught designer, I approach every challenge with
            curiosity, a problem-solving mindset, and a high-agency drive. I
            enjoy solving complex problems by combining creativity, logic, and
            systems thinking to create meaningful solutions.
          </p>
          <p className="abhero-description"> </p>
        </h1>
      </div>
      <TestimonialGrid></TestimonialGrid>
    </div>
  );
};

export default AboutHero;
