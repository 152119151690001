import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  MdHome,
  MdWork,
  MdBrush,
  MdPerson,
  MdDownload,
  MdFileOpen,
} from "react-icons/md";
import "./Navigation.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Workpopover from "./components/Workpopover";

const Navigation = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const handleResumeClick = () => {
    // Replace with your actual resume file path
    const resumeUrl = "/path-to-your-resume.pdf";
    const link = document.createElement("a");
    link.href = resumeUrl;
    link.download = "resume.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const navItems = [
    { path: "/home", icon: MdHome, text: "Home" },
    { path: "/about", icon: MdPerson, text: "About" },
    { path: "/art", icon: MdBrush, text: "Art" },
    { path: "/work", icon: MdWork, text: "Work" },
  ];

  return (
    <nav className="navigation">
      <div className="nav-content">
        {navItems.map(({ path, icon: Icon, text }) => (
          <Link
            key={path}
            to={path}
            className={`nav-item ${currentPath.includes(path) ? "active" : ""}`}
          >
            <Icon className="nav-icon" />
            <span className="nav-text">{text}</span>
          </Link>
        ))}

        {/* <Popup
          trigger={
            <div>
              {" "}
              <div
                className={`nav-item ${
                  currentPath.includes("work") ? "active" : ""
                }`}
              >
                <MdWork className="nav-icon" /> Work
              </div>
            </div>
          }
          position="bottom center"
        >
          <div>
            <Workpopover />
          </div>
        </Popup> */}

        <a
          className="nav-item resume-btn"
          href="https://drive.google.com/file/d/1ig2KoYZbhOTEy3Yr-qB3N8V-R1RrcNFM/view?usp=drive_link"
          target="blank"
        >
          <MdFileOpen className="nav-icon" />
          <span className="nav-text">Resume</span>
        </a>
      </div>
    </nav>
  );
};

export default Navigation;
