import React, { useState, useEffect } from "react";
import "./OldProjectCollection.css";

// Individual Project Component
const ProjectGrid = ({
  title,
  description,
  caption,
  images = [],
  columns = 3,
}) => {
  const [columnCount, setColumnCount] = useState(columns);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setColumnCount(1);
      } else if (window.innerWidth < 1024) {
        setColumnCount(2);
      } else {
        setColumnCount(columns);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [columns]);

  // Distribute images across columns
  const distributeImages = () => {
    const columnsArray = Array.from({ length: columnCount }, () => []);

    images.forEach((image, index) => {
      const columnIndex = index % columnCount;
      columnsArray[columnIndex].push(image);
    });

    return columnsArray;
  };

  const columnsArray = distributeImages();

  return (
    <div className="project-container">
      <div className="project-info">
        {caption && <div className="project-caption">{caption}</div>}
        {title && <h2 className="project-title">{title}</h2>}
        {description && (
          <div className="project-description">{description}</div>
        )}
      </div>

      <div className="project-masonry">
        {columnsArray.map((column, columnIndex) => (
          <div key={`column-${columnIndex}`} className="masonry-column">
            {column.map((image, imageIndex) => (
              <div
                key={`image-${columnIndex}-${imageIndex}`}
                className="masonry-item"
              >
                <img
                  src={image.url}
                  alt={image.alt || "Project image"}
                  className="masonry-image"
                />
                {image.overlay && (
                  <div className="image-overlay">{image.overlay}</div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

// Projects Collection Component
const OldProjectCollection = ({ projects = [] }) => {
  return (
    <div className="projects-collection">
      {projects.map((project, index) => (
        <div key={`project-${index}`} className="project-wrapper">
          <ProjectGrid
            title={project.title}
            description={project.description}
            caption={project.caption}
            images={project.images}
            columns={project.columns || 2}
          />
          {index < projects.length - 1 && (
            <div className="project-divider"></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OldProjectCollection;
