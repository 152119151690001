import React from "react";
import "./CSKeyresults.css";
import Button from "../../../Button/Button";
import { MdKeyboardDoubleArrowDown, MdOpenInNew } from "react-icons/md";
const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

const CSKeyresults = ({ keyResults, roleAndTeam, liveUrl }) => {
  return (
    <div>
      <div className="case-study-content-KR">
        {keyResults && (
          <div className="kr">
            <h4 className="cs-subtitle">Key Results</h4>
            <ul>
              {keyResults.map((item, index) => (
                <li
                  className="cs-li"
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: `${item}`,
                  }}
                ></li>
              ))}
            </ul>
          </div>
        )}

        {roleAndTeam && (
          <div>
            <h4 className="cs-subtitle">Role & Time</h4>
            <ul>
              {roleAndTeam.map((item, index) => (
                <li className="cs-li" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="cs-header-actions">
        {liveUrl && (
          <button
            onClick={() => window.open({ liveUrl }, "_blank")}
            className="cs-header-button"
          >
            {" "}
            View live website <MdOpenInNew />
          </button>
        )}
        <button
          onClick={() => scrollToSection("solution")}
          className="cs-header-link"
        >
          {" "}
          <MdKeyboardDoubleArrowDown />
          Scroll to solution
        </button>
      </div>
    </div>
  );
};

export default CSKeyresults;
