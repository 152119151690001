import React from "react";
import "./TestimonialGrid.css";
import { Button } from "@chakra-ui/react";
import { MdOpenInBrowser, MdOpenInNew } from "react-icons/md";

const testimonials = [
  {
    quote:
      "Devednya's commitment to collaborate with the Product Managers and refine  requirements has been truly commendable, showcasing his dedication to exceed expectations as a designer  ",
    author: "Ritesh Arora",
    role: "Co-Founder, CEO",
    company: {
      name: "BrowserStack",
      logo: "https://storage.googleapis.com/dv-portfolio-assets/bstack%20logo.svg",
    },
  },
  {
    quote:
      "His deep understanding of human behavior, mental models combined with engineering background, has made him an invaluable asset .",
    author: "Rohit Shintre",
    role: "Co-founder",
    company: {
      name: "Cadsol",
      logo: "https://storage.googleapis.com/dv-portfolio-assets/cadsol%20logo.svg",
    },
  },
];

const TestimonialGrid = () => {
  return (
    <div>
      {/* <h2 className="cs-title"> Reflections & Recognition</h2> */}
      <div className="testimonial-grid-container">
        <div className="testimonaial-left-container">
          <h4 className="caption exp-cap">Experience </h4>

          <div className="exp">
            <h6 className="exp-title"> BrowserStack</h6>
            <p className="exp-role--first"> Lead Product Designer</p>
            <p className="exp-year"> 2023-Current</p>

            <p className="exp-role--second"> Senior Product Designer</p>
            <p className="exp-year"> 2021-2023</p>
          </div>
          <div className="exp">
            <h6 className="exp-title"> Cadsol</h6>
            <p className="exp-role"> Associate Designer</p>
            <p className="exp-year"> 2019-2020</p>
          </div>
          <div className="exp">
            <h6 className="exp-title">Heltr Skeltr </h6>
            <p className="exp-role"> Visual Freelance Designer</p>
            <p className="exp-year"> 2018-2019</p>
          </div>
          <div className="exp">
            <h6 className="exp-title">DV Designs</h6>
            <p className="exp-role"> Freelance Design Services</p>
            <p className="exp-year"> 2017-2021</p>
          </div>
          <div className="exp-link--secondary">
            <a
              className="cs-header-button"
              href="https://in.linkedin.com/in/devednya-vyas-807575146"
            >
              View details on LinkedIN
            </a>{" "}
          </div>
          <div className="exp-link">
            <a
              className="cs-header-button-secondary"
              href="https://drive.google.com/file/d/1ig2KoYZbhOTEy3Yr-qB3N8V-R1RrcNFM/view"
            >
              View Resume
            </a>{" "}
          </div>

          {/* <div className="exp">
              <p>
                {" "}
                Early career experiences include volunteering and Internship.
                For more details
              </p>
            </div> */}
          {/* <div className="exp">
              <h6 className="exp-title">SocialBuzz</h6>
              <p className="exp-role"> Visual Design Intern</p>
              <p className="exp-year"> 2017-2021</p>
            </div>
            <div className="exp">
              <h6 className="exp-title"> Sporko</h6>
              <p className="exp-role--first"> Design & Operations head</p>
              <p className="exp-year"> 2017-2018t</p>

              <p className="exp-role--second">Volunteer</p>
              <p className="exp-year"> 2014-2017</p>
            </div> */}
        </div>
        <div className="testimonaial-right-container">
          <div>
            <h4 className="caption"> My Story</h4>
            <p className="cs-description">
              {" "}
              My journey with design began by exploring creativity through
              designing presentations. This sparked my curiosity and nurtured my
              ability to experiment and learn independently. While pursuing my
              bachelor's degree, I actively volunteered —an experience that not
              only helped me grow my skills but also taught me life lessons.
            </p>
            <p className="cs-description">
              {" "}
              As I stepped into my professional career, I explored diverse
              opportunities, seeking the right balance between passion,
              learning. Through it all, I’ve always been drawn to working with
              motivated teams—those who strive to do their best work and push
              the boundaries of what’s possible.
            </p>
          </div>
          <div className="testimonaial-left-container">
            <h4 className="caption"> Recognitions</h4>
            <div className="testimonial-grid">
              {testimonials.map((testimonial, index) => (
                <div key={index} className="testimonial-card">
                  <div className="testimonial-content">
                    <p className="quote">{testimonial.quote}</p>
                    <div className="author-info">
                      <div className="details">
                        <h3 className="author-name">{testimonial.author}</h3>
                        <p className="author-role">{testimonial.role}</p>
                      </div>
                    </div>
                    <div className="company-info">
                      <img
                        src={testimonial.company.logo}
                        alt={`${testimonial.company.name} logo`}
                        className="company-logo"
                      />
                      {/* <img
                        src={testimonial.avatar}
                        alt={`${testimonial.author} avatar`}
                        className="author-avatar"
                      /> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialGrid;
