import React from "react";
import Slider from "react-slick";
import "../CasestudyItem/CasestudyItem.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CSHeader from "../CSHeader/CSHeader";
import CSKeyresults from "../CSKeyresults/CSKeyresults";
import CSOverview from "../CSOverview/CSOverview";
import CSResearch from "../CSResearch/CSResearch";
import CSSolution from "../CSSolution/CSSolution";
import SectionNavigator from "../../../SectionNavigator/SectionNavigator";
import CSFeature from "../CSFeature/CSFeature";
import TopGradient from "../../../TopGradient/TopGradient";

const CaseStudyItem = ({ data }) => {
  return (
    <div className="case-study-item">
      <TopGradient />
      <div className="case-study-content">
        {data && data.header && (
          <CSHeader
            title={data.header.title}
            description={data.header.description}
          />
        )}
        {data && (data.keyResults || data.roleAndTeam || data.liveUrl) && (
          <CSKeyresults
            keyResults={data.keyResults}
            roleAndTeam={data.roleAndTeam}
            liveUrl={data.liveUrl}
          />
        )}
      </div>

      <div>
        {data.heroImage && (
          <img
            className="cs-hero"
            src={data.heroImage.url}
            alt={data.heroImage.alt}
            onContextMenu={(e) => e.preventDefault()}
          />
        )}
        {data.heroImage && <p className="cs-alttext">{data.heroImage.alt}</p>}
      </div>

      <div>
        {data.overview &&
          (data.overview.title || data.overview.description) && (
            <CSOverview
              title={data.overview.title}
              description={data.overview.description}
              secondaryTitle={data.overview.secondaryTitle}
            />
          )}
      </div>
      <div>
        {data &&
          data.research &&
          data.research.map((researchItem, key) => {
            return (
              <div>
                {data.research && (
                  <CSResearch
                    key={key}
                    title={researchItem.title}
                    secondaryTitle={researchItem.secondaryTitle}
                    description={researchItem.description}
                    iframeurl={researchItem.iframeurl}
                    img={researchItem.img}
                    alt={researchItem.alt}
                    secondaryTitleTwo={researchItem.secondaryTitleTwo}
                    descriptionTwo={researchItem.descriptionTwo}
                    imgTwo={researchItem.imgTwo}
                    altTwo={researchItem.altTwo}
                    summary={researchItem.summary}
                    summaryTitle={researchItem.summaryTitle}
                  />
                )}
              </div>
            );
          })}
      </div>
      <div>
        {data.solution && (data.solution.video || data.solution.img) && (
          <CSSolution
            // title={data.solution.title}
            video={data.solution.video}
            img={data.solution.img}
          />
        )}
        {data &&
          data.feature &&
          data.feature.map((featureItem, key) => {
            return (
              <CSFeature
                key={key}
                title={featureItem.title}
                description={featureItem.description}
                img={featureItem.img}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CaseStudyItem;
