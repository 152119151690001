import { title } from "framer-motion/client";
import React from "react";
import "./CSHeader.css";

const CSHeader = ({ title, description }) => {
  return (
    <div className="cs-headername">
      <h1 className="cs-title">{title}</h1>
      <p
        className="cs-description"
        dangerouslySetInnerHTML={{
          __html: `${description}`,
        }}
      ></p>
    </div>
  );
};

export default CSHeader;
