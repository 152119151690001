import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const CSFeature = ({ title, description, img }) => {
  return (
    <div className="cs-feature">
      <div className="cs-feature-content">
        <h4 className="cs-subtitle"> {title} </h4>
        <p className="cs-description"> {description}</p>
      </div>
      <Zoom>
        <img className="cs-feature-img" src={img} alt="" />
      </Zoom>
    </div>
  );
};

export default CSFeature;
