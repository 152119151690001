import React from "react";
import "./Artworks.css";
import ScatteredPolaroids from "./../ScatteredPolaroids/ScatteredPolaroids";
import { useState } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Artworks = () => {
  const navigate = useNavigate();
  return (
    <div className="artworks">
      <ScatteredPolaroids
        keepScattered={true}
        polaroidsToShow="14"
      ></ScatteredPolaroids>
      <div className="artworks-note">
        <h3 className="artworks-title">Artworks</h3>
        <p className="artworks-para">
          I manipulate photos as a form of expression. My artworks are blend of
          outer space, adventure, and a sense of peace—each piece reflecting a
          part of my personal journey.
        </p>

        <p className="artworks-para">
          PS: Since the AI era, I haven’t revisited this passion yet
        </p>
        <div className="artworks-note-action">
          <button onClick={() => navigate("/art")} className="cs-header-button">
            {" "}
            View artworks <MdKeyboardDoubleArrowRight />{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Artworks;
