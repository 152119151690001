import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "../CurrentWork/CurrentWork.css";
import "../CurrentWork/assets/showcase.png";
import "../CurrentWork/assets/showcase2.png";
import "./Auth.css";
import useCookieValidator from "./useCookieValidator";
import lockIcon from "./assets/Locked.png";

const passwordHash = process.env.REACT_APP_PASSWORD_HASH;

const Authentication = () => {
  const [validator] = useCookieValidator();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const correctPassword = passwordHash;
  const [cookies, setCookie] = useCookies(["config"]);

  function handleLogin(user) {
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + 15 * 60000);
    setCookie("config", passwordHash, { path: "/", expires: newDate });
  }
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const userPasswordHash = CryptoJS.SHA256(password).toString();
    if (userPasswordHash === correctPassword) {
      setIsAuthenticated(true);
      handleLogin("user");
      setError("");
      navigate("/work/authPass");
    } else {
      setError("Please enter the correct password");
    }
  };

  useEffect(() => {
    if (validator(passwordHash)) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <div>
      {!isAuthenticated ? (
        <form className="Auth" onSubmit={handleSubmit}>
          <img className="Auth-lockImg" src={lockIcon}></img>
          <h2 className="Auth-title">This content is protected</h2>
          <p className="Auth-message">
            I can not share many details here, if you are a hiring manager you
            can find password in my resume
          </p>
          <div className="Auth-action">
            {" "}
            <input
              className="Auth-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
            />
            <button className="Auth-button" type="submit">
              Unlock
            </button>
          </div>
          {error && <p className="Auth-error">{error}</p>}
        </form>
      ) : (
        <>
          <button
            className="Auth-button"
            onClick={() => navigate("/work/casestudies")}
          >
            View Case Studies
          </button>

          {/* <CookiesProvider>
            <div></div>
          </CookiesProvider> */}
        </>
      )}
    </div>
  );
};

export default Authentication;
