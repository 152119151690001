import React, { useLayoutEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage/HomePage";
import { CookiesProvider, useCookies } from "react-cookie";
import LoginPage from "./pages/LoginPage/CaseStudiesListing";
import CurrentWork from "./components/CurrentWork/CurrentWork";
import Art from "./pages/Art/Art";
import Work from "./pages/Work/Work";
import Casestudies from "./components/Casestudies/Casestudies";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
// import CasestudyItem from "../src/components/CasestudyItem/CasestudyItem";
import NotificationSystem from "../src/customHooks/NotificationSystem/NotificationSystem";
import About from "./pages/About/About";
import OldProjectCollection from "./pages/OldprojectsPage/components/OldProjectCollection/OldProjectCollection";
import Oldprojects from "./pages/OldprojectsPage/Oldprojects";
import Volunteering from "./pages/Volunteering/Volunteering";
const myPassword =
  "28362850e109408be5e600c229bd4ccc5da2145aa52204863539728ef479640f";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return children;
};

const App = () => {
  return (
    <div className="App">
      <NotificationSystem />
      <Wrapper>
        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/homepage" element={<HomePage />} />
          <Route path="/work/old" element={<Oldprojects />} />
          <Route path="/work">
            <Route index element={<Work />} />
            <Route element={<ProtectedRoute />}>
              <Route path="auth:true" element={<LoginPage />} />
              <Route path="casestudies" element={<LoginPage />} />
              <Route path="casestudies/:casestudy" element={<Casestudies />} />
            </Route>
          </Route>
          <Route path="/art" element={<Art />} />
          <Route path="/about/volunteer" element={<Volunteering />} />
        </Routes>
      </Wrapper>
    </div>
  );
};

export default App;
