import React from "react";
import TopGradient from "../../components/TopGradient/TopGradient";
import Navigation from "../../components/Navigation/Navigation";
import Hero from "../../components/Hero/Hero";
import AboutHero from "./components/AboutHero/AboutHero";
import PolaroidGallery from "./components/PoloroidGallery/PoloroidGallery";
import TestimonialGrid from "../../components/TestimonialGrid/TestimonialGrid";
import Slider from "react-slick";
import ImageSlider from "./components/Slider/Slider";

const About = () => {
  return (
    <div>
      <TopGradient />
      <Navigation />
      <AboutHero />
      <ImageSlider></ImageSlider>
    </div>
  );
};

export default About;
