import React from "react";
import "./Art.css";
import ScatteredPolaroids from "./../../components/ScatteredPolaroids/ScatteredPolaroids";
import Navigation from "./../../components/Navigation/Navigation";
import TopGradient from "../../components/TopGradient/TopGradient";

function Art() {
  return (
    <div>
      <Navigation></Navigation>
      <TopGradient></TopGradient>
      <ScatteredPolaroids></ScatteredPolaroids>
    </div>
  );
}

export default Art;
