import React from "react";
import "./CurrentWork.css";
import arrowIcon from "./assets/arrowIcon.svg";
import sgiImg from "./assets/sgiImg.png";
import codeDev from "./assets/dev.png";
import showcase from "./assets/showcase.png";
import showcase2 from "./assets/showcase2.png";
import showcase3 from "./assets/Debugging.png";
import bstackLogo from "./assets/bstackLogo.svg";
import Expcard from "./assets/Expcard";
import Authentication from "../Auth/Auth";

const CurrentWork = () => {
  return (
    <section id="work" className="Currentwork">
      <div className="Currentwork-content">
        <h5 className="Caption">Current Work</h5>
        <img className="Currentwork-Logo" src={bstackLogo}></img>
        <h2 className="Title Currentwork-Title">
          Designing for
          <span>
            <img className="Currentwork-codeDev" src={codeDev}></img>
          </span>
          <span className="Mono mono-QE"> QA Engineers</span>,<br></br> &
          Building
          <span></span>
          <span className="Display">
            {" "}
            <img className="Currentwork-arrow" src={arrowIcon}></img> Test
            Observability
          </span>
        </h2>
      </div>
      <p className="Currentwork-para">
        At BrowserStack, I am leading 0 to 1 design efforts to solve test
        reporting, failure debugging, & QA analytics challenges key to the
        company's goal of being a developer-first, end-to-end testing platform.
      </p>
      {/* <p className="Currentwork-para">
        Currently, I'm spearheading design efforts for BrowserStack Test
        Observability, leading a talented team of designers. Beyond my primary
        responsibilities, I'm passionate about fostering a culture of excellence
        by providing effective design reviews and sharing valuable context from
        past experiences.
      </p> */}
      <div className="Cunrrentwork-exp">
        <div className="Cunrrentwork-card big-card magicpattern-1">
          <div>
            <h4 className="Cunrrentwork-cardTitle">
              Designing <span className="InterArrow"> </span> Test Observability
            </h4>
            <p className="Cunrrentwork-cardpara">
              Designed Test Observability from scratch, and the journey
              continues...
            </p>
          </div>
          <img className="Cunrrentwork-cardImgs" src={showcase}></img>
        </div>

        <div className="auth">
          <Authentication></Authentication>
        </div>
        {/* <div className="row">
          <div className="Cunrrentwork-card green-card smallcard">
            <h4 className="Cunrrentwork-cardTitle">
              BrowserStack SDK Onboarding
            </h4>
            <p className="Cunrrentwork-cardpara">
              In the early days of BrowserStack SDK, worked on experiments to
              boost adoption and created an onboarding configurator.
            </p>
            <img className="smallcard-cardImgs" src={showcase2}></img>
          </div>
          {/* </Link> */}
        {/* <div className="Cunrrentwork-card purple-card smallcard">
          <h4 className="Cunrrentwork-cardTitle">
            Improving Debugging Experience
          </h4>
          <p className="Cunrrentwork-cardpara">
            Improved navigation for faster build debugging and resolved minor
            issues to enhance debugging efficiency.
          </p>
          <img className="smallcard-cardImgs" src={showcase3}></img>
        </div> */}
      </div>

      {/* <div className="Currentwork-exp">
        <Expcard
          title={"Zero to one product"}
          content={
            "Designed Test Observability from scratch, and the journey continues... "
          }
          colour={"grey"}
        ></Expcard>
        <Expcard
          title={"Onboarding"}
          content={
            "Worked on onboarding journey Of SDK. Helped in writing docs & developing configurable widgets "
          }
          colour={"grey"}
        ></Expcard>
        <Expcard
          title={"Debugging Experience"}
          content={
            "Worked on navigation to reduce debugging time and syncing logs & video to find errors faster"
          }
          colour={"grey"}
        ></Expcard>
        <Expcard
          title={"Design System"}
          content={
            "Contributed by creating new components & patterns, wrote documentation "
          }
          colour={"grey"}
        ></Expcard>
      </div> */}

      <div className="other-work">
        <div className="Currentwork-content">
          <h5 className="Caption">2017 ~ 2021</h5>
          <h2 className="Title Currentwork-Title">Miscellaneous Work</h2>
          <p className="Currentwork-para">
            From 2017-2021, I explored diverse opportunities by working with
            design agencies, CAD solutioning firm, a football academy,
            freelancing, and assisting startups, independently adapting and
            problem-solving along the way.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CurrentWork;
