import { title } from "framer-motion/client";
import React from "react";
import { useParams } from "react-router-dom";

function CSFooter({ casestudiesData }) {
  const { casestudy } = useParams();

  // Filter out the current case study and create an array of other case studies
  const otherCaseStudies = Object.entries(casestudiesData)
    .filter(([key]) => key !== casestudy)
    .slice(0, 1)
    .map(([key, value]) => ({
      title: value.header.title,
      imageUrl: value.heroImage.url,
      id: key,
    }));
  console.log({ title });

  return (
    <div className="cs-footer">
      <h2 className="cs-footer-title">Next Case Study</h2>
      <div className="cs-footer-box">
        {otherCaseStudies.map((study) => (
          <div
            key={study.id}
            className="cs-footer-card"
            onClick={() =>
              (window.location.href = `/work/casestudies/${study.id}`)
            }
          >
            <h3 className="cs-footer-card-title">{study.title}</h3>
            {/* <img
              src={study.imageUrl}
              alt={study.title}
              className="cs-footer-img"
            /> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CSFooter;
