import { summary, title } from "framer-motion/client";
import React, { useEffect, useState } from "react";
import CaseStudyItem from "./components/CasestudyItem/CasestudyItem.jsx";
import heroImages from "../Hero/assets/images";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import CSFooter from "./components/CSFooter/CSFooter.jsx";
import SectionNavigator from "../SectionNavigator/SectionNavigator.jsx";

const casestudiesData = {
  case_study_3: {
    header: {
      title: "Designing Paywalls for changing business needs",
      description: `As the business evolved, we adjusted our pricing plans, implementing more restrictive feature fencing to encourage upgrades. <strong> My role involved designing new patterns to align with the business needs.</strong>`,
    },
    keyResults: [
      "<b>2X increase</b> in contact sales form submissions ",
      "<b> 17%  conversion</b> to paid plan post fencing",
    ],
    roleAndTeam: ["Lead Product Designer", "1 month"],

    summary: ["Senior Product Designer"],
    heroImage: {
      url: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/paywall/paywallhero.png",
      alt: "hero image",
    },
    overview: {
      title: "Challenge",
      description: [
        `Most users were satisfied with our Freemium plan and did not see enough value in upgrading to the Pro plan. Our goal was to restrict Freemium features strategically, making Pro more compelling while ensuring Freemium remained attractive to new users switching from competitors.`,
      ],
    },
    research: [
      {
        title: `Research & Insights`,
        secondaryTitle: "Understanding industry patterns",
        description: [
          `To determine the best approach, I studied different feature fencing models across the industry: `,
          `<b>Soft fencing </b> (e.g., Figma, Miro, LinkedIn) – Users can try features in a limited way before upgrading.  `,
          `<b>Hard fencing </b>  (e.g., Netflix, news sites) – Features are completely locked behind a paywall.  `,
          `<b>Metered fencing </b> – Users get a limited quota before requiring an upgrade.  `,
        ],
        img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/paywall/patterns.png`,
        summary: [
          `<b>Pro tags and locked icons</b> were commonly used to indicate restricted features.`,
          `<b>Blurred content overlays</b> created intrigue and encouraged upgrades.`,
          "<b>Usage indicators</b> helped comminicate limits and anticipate restrictions..",
        ],
        summaryTitle: "Key Findings",
      },

      {
        title: `Previous Freemium Model`,
        description: [
          `Our initial freemium model offered most features for free, with some metered restrictions on high-demand features—similar to Miro, where users could try features in a limited way before needing to pay. `,
        ],
        img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/paywall/Modals.png`,
      },
      {
        title: `Design Explorations: Creating new Patterns`,
        description: [
          `To create paywalls, I explored three approaches: empty states, new card designs, and blurred overlays. `,
          `<li classname="cs-li"><b>Empty states</b> were the quickest to implement but resembled error screens and lacked visual appeal.</li> `,
          `<li classname="cs-li"> <b>New card designs</b> added visual interest but failed to emphasize the value users were missing..</li> `,
          `<li classname="cs-li"><b>Blurred overlays </b> effectively conveyed missing value and encouraged upgrades.</li> `,
          `After reviewing these options, I aligned the team to integrate blurred overlays into the design system, making it the ideal paywall solution.`,
        ],

        iframeurl:
          "https://embed.figma.com/board/lDwTcGquYupswiQ2ixAxvP/Paywall-Research?node-id=19-86182&embed-host=share",
      },
      {
        secondaryTitle: "Reverse Engineering",
        description: [
          `One of the biggest challenges was implementing blurred overlays seamlessly. <b>Data behind the overlay</b> was the reason of concern as target users of the product-QA's could easily manipulate CSS.`,
          `I analyzed how other companies achieved this, and developed a solution with front-end team to enter dummy data for  a smooth user experience. `,
        ],
        img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/paywall/reverse%20engineering.png`,
      },
    ],
    solution: {
      img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/paywall/showcase1.png",
    },
    feature: [
      {
        title: "Communicating usage ",
        description:
          "One of the important aspect of fencing is to communicate usage. We created in product touch point where users could monitor the usage.",
        img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/paywall/feature%201-1.png",
      },
      {
        title: "Warning before restrictions ",
        description:
          "To warn users about the limits we used alerts within products as well as email notifications increasing upgrade touchpoints at the same time keeping users informed.",
        img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/paywall/feature%202-1.png",
      },
      {
        title: "Extending the pattern usage",
        description:
          "I extended the blurred overlay pattern to other parts of the product to maintain consistency and reinforce the value of the Pro plan.",
        img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/paywall/feature%203.png",
      },
    ],
  },
  case_study_2: {
    header: {
      title: "Dashboards for visibility of automation testing metrics",
      description: `After the beta launch of Test Observability, I collaborated with a
          newly joined Product Manager to design and implement a Dashboards
          feature. My role focused on <strong>defining direction and design execution to
          enhance user experience to meet evolving needs.</strong>`,
    },
    keyResults: [
      "Attracted new <strong>customers</strong> within the first month of launch.",
      "Differentiated the product from competitors.",
      "Developed reusable design patterns for the Design System.",
    ],
    roleAndTeam: ["Senior Product Designer", "2 months"],
    liveUrl: "https://observability-demo.browserstack.com/dashboards",
    heroImage: {
      url: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/hero.png",
      // alt: "^ Above, image of dashboard created for visibility of automation testing metrics",
    },
    overview: {
      title: "Overview",
      description: [
        `During Beta, Test Observability primarily helped QE engineers debug
          tests efficiently. We provided pre-built dashboards that displayed
          automation trends.`,
        `However, as enterprise teams and QA managers adopted the product, we
          identified a new opportunity—QA Analytics. These users needed deeper
          insights and custom reporting to showcase data to leadership and
          monitor team performance.`,
      ],
    },
    research: [
      {
        title: `Research & Discovery: Deep-diving on needs`,
        description: [
          `To uncover user pain points, I analyzed more than 10 recorded customer interactions and interviewed 4 internal QA managers / leads. `,
          `Customers wanted a way to <strong> present key insights while focusing on specific data </strong>, not everything. For. e.g. Managers needed to monitor their teams' work, while Testing Architects prioritized showcasing flakiness to leadership.  `,
        ],
        secondaryTitle: "Understanding User Needs",
        img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/research.png`, // iframeurl:
        //   "https://embed.figma.com/board/16GLKpe8OeC4aigIhkic14/Dashboard-Competitors?node-id=24-6065&embed-host=share%26hide-ui=1",
      },
      {
        secondaryTitle: "Secondary Research",
        description: [
          "I analyzed multiple tools to understand dashboard creation approaches. <b>There was no standard method</b>—some tools offered inline editing, while others used save-on-the-fly functionality.<b> Each tool's context influenced its unique solution.</b>",
        ],
        // img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/secondary%20research.png`,
        iframeurl:
          "https://embed.figma.com/board/16GLKpe8OeC4aigIhkic14/Dashboard-Competitors?node-id=111-6178&embed-host=share",
        summary: [
          `Widget Management— <b>Developer–focused tools</b> like Sentry, Grafana, and Elastic <strong> commonly use a dedicated page for widget creation.</strong>`,
          `Dashboard Listing Views – <b>Table and grid views were the standard</b> formats for listing dashboards.`,
          "Templates – Providing ready-to-use templates helped users get started quickly.",
        ],
        summaryTitle: "Summary",
      },
      {
        title: `Design Explorations`,
        description: [
          `I began my exploration journey by breaking down user tasks to evaluate different approaches. `,
          `I focused on the <b>creation of workflows first</b> as patterns for CRUD were already defined and did not require re-inventing the wheel.`,
          `I created variations for: Dashboard listing , creation & widget creation`,
        ],
        iframeurl:
          "https://embed.figma.com/board/16GLKpe8OeC4aigIhkic14/Dashboard-Competitors?node-id=129-6065&embed-host=share",
      },
      {
        description: [
          `I reviewed these ideas with designers and stakeholders, most of the feedback belong to widget creation leading to three key learnings: `,
          `<li classname ="cs-li"> Widget creation concepts lacked <b>real-time widget previews</b>, making them less intuitive.</li>`,
          `<li classname ="cs-li"> <b>Multi-step modals  felt overwhelming</b> and slowed down the user experience.</li>`,
          ` Directionally we wanted to provide <b> faster and more flexible way </b> to build dashboards. `,
        ],
      },
      {
        secondaryTitle: "Iterate>Iterate>Iterate",

        description: [
          `Although it may look straightforward, the process was jumbled up as any design process.

. `,
          ` After several jamming sessions & reviews, I formed my opinion on the final solution`,
        ],
        img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/explorations.png`,
      },
    ],
    solution: {
      video:
        "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/video.mp4",
      // img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/hero.png",
    },
    feature: [
      {
        title: "Frictionless Dashboard Creation",
        description:
          "To reduce friction while dashboard creation we landed users directly in an empty dashboard with suggested widgets. This allowed them to begin building immediately, reducing hesitation. ",
        img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/empty%20dash.png",
      },
      {
        title: "Smoother Widget creation",
        description:
          "Real time preview of the widget was a musthave. To align with engineering, I demonstrated the impact of additional clicks and referenced UX heuristics on system feedback. This helped gain buy-in for a more interactive widget creation process.",
        img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/add%20widget%20modal.png",
      },
      {
        title: "End to end customisability",
        description:
          "We solved multiline charts (segments), which provided a flexible way for users to represent their data and compare trends. Also powerful widgets such as summary banner and component summary highlight metrics enabled leadership view creation for QA managers ",
        img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/customisability.png",
      },
    ],
  },
  case_study_4: {
    header: {
      title: "Quality Gates to ensure quality at every CI/CD step",
      description: `Led UX design for the Quality Gate feature, enabling Quality Excellence teams to enforce consistent standards across the organization by creating simplified rule creation and specific test selection.`,
    },
    // keyResults: [
    //   "<b>52% adoption</b> of quality gates",
    //   "<b>reduced number of clicks</b> for finding builds..",
    // ],
    liveUrl:
      "https://observability-demo.browserstack.com/projects/Test+Observability+Samples/settings/quality_gate",
    summary: ["Senior Product Designer"],
    heroImage: {
      url: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/QG/qghero.png",
    },

    research: [
      {
        title: "Concept & Usecases",
        description: [
          `As organizations adopt automated deployments, maintaining quality at every stage becomes crucial. Poor-quality code can progress through the pipeline without proper validation mechanisms, leading to unstable releases and production issues`,
          `<b>Transition from CI to CD:</b>  `,
          ` -  Helping users to do automated deployments by checking quality rules`,
          `-Prevent false posives/negatives from impacting promotion of code to the next stage of pipeline.  
eg: If P0/P1 test cases are passing then then deploy the build 
`,
          `<b>Maintaining Quality Standards 
:</b>  `,
          `- Allowing organisations to implement uniform quality standards across teams, projects. 
eg: Fail the buid and stop further deployments if new failures are present.

`,
        ],

        img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/QG/qg2.png`, // iframeurl:
        //   "https://embed.figma.com/board/16GLKpe8OeC4aigIhkic14/Dashboard-Competitors?node-id=24-6065&embed-host=share%26hide-ui=1",
      },

      {
        title: `Research & Insights`,
        description: [
          "To build an effective solution, I conducted secondary research on existing quality gate implementations in DevOps.",
        ],
        // img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/secondary%20research.png`,
        iframeurl:
          "https://embed.figma.com/board/16GLKpe8OeC4aigIhkic14/Dashboard-Competitors?node-id=723-6082&embed-host=share",
        summary: [
          `Quality gates follow a <b>CRUD workflow,</b> allowing users to define, edit, and manage rules.`,
          `Most implementations use predefined metrics (e.g., SonarQube’s “code smell”) to determine quality.`,
          "Quality gate statuses are displayed alongside builds, showing thresholds and actual values for visibility.",
        ],
        summaryTitle: "Summary",
      },
      {
        title: `Information Architecture`,
        description: [
          `A quality gate system consists of three key components: `,
          `-<b>Quality Gate</b>: A decision-making checkpoint that results in either a pass or fail.`,
          `-<b>Quality Profile</b>: A collection of grouped rules that define quality standards.`,
          `-<b>Rule</b>: A condition based on a metric that determines the success or failure of a build.`,
        ],
        img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/QG/qg4.png`,
      },
      // {
      //   title: `Design Explorations`,
      //   description: [
      //     `I began my exploration journey by breaking down user tasks to evaluate different approaches. `,
      //     `I focused on the <b>creation of workflows first</b> as patterns for CRUD were already defined and did not require re-inventing the wheel.`,
      //     `I created variations for: Dashboard listing , creation & widget creation`,
      //   ],
      //   iframeurl:
      //     "https://embed.figma.com/board/16GLKpe8OeC4aigIhkic14/Dashboard-Competitors?node-id=129-6065&embed-host=share",
      // },
      //       {
      //         description: [
      //           `I reviewed these ideas with designers and stakeholders, most of the feedback belong to widget creation leading to three key learnings: `,
      //           `<li classname ="cs-li"> Widget creation concepts lacked <b>real-time widget previews</b>, making them less intuitive.</li>`,
      //           `<li classname ="cs-li"> <b>Multi-step modals  felt overwhelming</b> and slowed down the user experience.</li>`,
      //           ` Directionally we wanted to provide <b> faster and more flexible way </b> to build dashboards. `,
      //         ],
      //       },
      //       {
      //         secondaryTitle: "Iterate>Iterate>Iterate",

      //         description: [
      //           `Although it may look straightforward, the process was jumbled up as any design process.

      //  `,
      //           ` After several jamming sessions & reviews, I formed my opinion on the final solution`,
      //         ],
      //         img: `https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/explorations.png`,
      //       },
    ],
    solution: {
      video:
        "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/QG/QGVideo.mp4",
      // img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/hero.png",
    },

    feature: [
      // {
      //   title: "Frictionless Dashboard Creation",
      //   description:
      //     "To reduce friction while dashboard creation we landed users directly in an empty dashboard with suggested widgets. This allowed them to begin building immediately, reducing hesitation. ",
      //   img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/empty%20dash.png",
      // },
      // {
      //   title: "Smoother Widgget creation",
      //   description:
      //     "Real time preview of the widget was a must-have. To align with engineering, I demonstrated the impact of additional clicks and referenced UX heuristics on system feedback. This helped gain buy-in for a more interactive widget creation process.",
      //   img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/add%20widget%20modal.png",
      // },
      // {
      //   title: "End to end customisability",
      //   description:
      //     "We solved multiline charts (segments), which provided a flexible way for users to represent their data and compare trends. Also powerful widgets such as summary banner and component summary highlight metrics enabled leadership view creation for QA managers ",
      //   img: "https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/customisability.png",
      // },
    ],
  },
};

function Casestudies() {
  const [selectedCasestudy, setSelectedCasestudy] = useState({});
  let navigate = useNavigate();
  let { casestudy } = useParams();
  useEffect(() => {
    const targetCasestudy = Object.keys(casestudiesData).find(
      (key) => key === casestudy
    );
    if (targetCasestudy) {
      setSelectedCasestudy(casestudiesData[targetCasestudy]);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <Navigation></Navigation>
      <CaseStudyItem data={selectedCasestudy} />
      <CSFooter casestudiesData={casestudiesData}></CSFooter>
      <Footer></Footer>
      <SectionNavigator></SectionNavigator>
    </div>
  );
}

export default Casestudies;
