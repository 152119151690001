import React from "react";

const CSSolution = ({ title, video = "", img }) => {
  return (
    <div>
      <div className="case-study-content" id="solution">
        <h2 className="cs-title">Design Showcase: The final solution</h2>
      </div>
      {video && <video loop controls className="cs-hero" src={video} />}

      {img && (
        <img className="cs-hero" src={img} alt="Design solution showcase" />
      )}
    </div>
  );
};

export default CSSolution;
