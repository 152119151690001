import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <marquee className="Footer-text">
        Work in progress • Coming soon • Get in touch for work quries © WIP •
        Coming soon • Contact for more details © WIP • Coming soon • Contact for
        more details © WIP • Coming soon • Contact for more details © Work in
        progress • Coming soon • Get in touch for work quries © WIP • Coming
        soon • Contact for more details © WIP • Coming soon • Contact for more
        details © WIP • Coming soon • Contact for more details © Work in
        progress • Coming soon • Get in touch for work quries © WIP • Coming
        soon • Contact for more details © WIP • Coming soon • Contact for more
        details © WIP • Coming soon • Contact for more details © Work in
        progress • Coming soon • Get in touch for work quries © WIP • Coming
        soon • Contact for more details © WIP • Coming soon • Contact for more
        details © WIP • Coming soon • Contact for more details © Work in
        progress • Coming soon • Get in touch for work quries © WIP • Coming
        soon • Contact for more details © WIP • Coming soon • Contact for more
        details © WIP • Coming soon • Contact for more details © Work in
        progress • Coming soon • Get in touch for work quries © WIP • Coming
        soon • Contact for more details © WIP • Coming soon • Contact for more
        details © WIP • Coming soon • Contact for more details ©
      </marquee>
    </div>
  );
};

export default Footer;
