import React from "react";
import Dashboards from "./assets/dashboards.svg";
import Paywalls from "./assets/paywalls.svg";
import TestReport from "./assets/reports.svg";
import Navigation from "../../components/Navigation/Navigation";
import Bstack from "./assets/bstack.svg";
import topgradient from "./assets/gradient.svg";
import patternone from "./assets/pattern-1.svg";
import "./CaseStudiesListing.css";
import Footer from "../../components/Footer/Footer";
import Designstack from "./assets/designstack.png";
import git from "./assets/git.png";
import behindstack from "./assets/behindstack.png";
import { Link } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function LoginPage() {
  return (
    <>
      <section>
        <Navigation></Navigation>
        <div className="Casestudy-page">
          {/* <div className="Casestudy-pageheading">
            <div className="Casestudy-pagecontainer">
              <button onClick={() => navigate("/")} className="back-button">
                <MdArrowBack /> Back
              </button>
              <h1 className="Casestudy-pageTitle">Case Studies</h1>
            </div>
          </div> */}

          <header className="Bstackwork-container">
            <p className="Bstackwork-caption">
              Solving #real problems with #speed matters
            </p>
            <h1 className="Bstackwork-title">
              At{" "}
              <span className="arrow" role="img" aria-label="BrowserStack">
                <img className="arrow-icon" src={Bstack}></img>
                {/* <img className="arrow-icon" src={BstackLogo}></img> */}
              </span>{" "}
              BrowserStack, I am leading
              <span className="Bstackwork-titlepill">0 to 1 </span> design
              efforts to solve{" "}
              <span role="img" aria-label="TReporting">
                📑test
              </span>{" "}
              reporting,
              <span role="img" aria-label="Debugging">
                {" "}
                ⚒️
              </span>
              debugging, &
              <span role="img" aria-label="Analytics">
                {" "}
                📊analytics
              </span>{" "}
              challenges key to the company's goal of being a
              <span role="img" aria-label="Developer">
                👩‍💻developer-first,
              </span>{" "}
              end-to-end testing platform.
            </h1>
          </header>

          <div className="Casestudy-container">
            <h5 className="Casestudy-container-title">Selected Work </h5>
            <Link to="/work/casestudies/case_study_2">
              <div className="Casestudy-item ">
                {/* <img className="patternone" src={patternone}></img> */}
                <div className="Casestudy-item-content">
                  <p className="Casestudy-item-cap">📊 Analytics</p>
                  <h4 className="Casestudy-item-title">
                    Dashboards for visibility of automation testing metrics
                  </h4>
                  <p className="Casestudy-item-description">
                    Designed the Dashboards feature to help QA Managers create
                    filtered data visualisations and track automation metrics
                    efficiently.
                  </p>
                </div>
                <img
                  className="Casestudy-item-img"
                  src="https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/dashthumb.png"
                ></img>
              </div>
            </Link>
            <div className="row-work">
              {/* <div className="Casestudy-item-vertical">
                <div className="Casestudy-item-content Casestudy-item-content-vertial">
                  <p className="Casestudy-item-cap">
                    📑 Reporting & ⚒️ Debugging
                  </p>
                  <h4 className="Casestudy-item-title">
                    Test reports that cuts noise & saves debugging time
                  </h4>
                  <p className="Casestudy-item-description">
                    Helped users to track and create Organization wide
                    visibility of Automation Test Suite metrics
                  </p>
                </div>
                <img className=" report-img" src={TestReport}></img>
              </div> */}
              <Link
                className="Casestudy-item-vertical-wrapper"
                to="/work/casestudies/case_study_4"
              >
                <div className="Casestudy-item-vertical ">
                  <div className="Casestudy-item-content-vertical ">
                    <p className="Casestudy-item-cap">🎯 Monitoring</p>
                    <h4 className="Casestudy-item-title">
                      Quality gates to ensure quality at every CI/CD step
                    </h4>
                    <p className="Casestudy-item-description">
                      Led UX design for the Quality Gate feature helping QA
                      teams achieve excellence.
                    </p>
                  </div>
                  <img
                    className="vertical-img "
                    src="https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/QG/QGThumb3.png"
                  ></img>
                </div>
              </Link>
              <div className="column-work">
                <Link to="/work/casestudies/case_study_3">
                  <div className="Casestudy-item-vertical ">
                    <div className="Casestudy-item-content-vertical ">
                      <p className="Casestudy-item-cap">💰 Monetisation</p>
                      <h4 className="Casestudy-item-title">
                        Paywalls & fencing for changing business needs
                      </h4>
                      <p className="Casestudy-item-description">
                        Created patterns for implementing restrictive fencing to
                        encourage upgrades
                      </p>
                    </div>
                    <img
                      className="vertical-img "
                      src="https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/paywall/paywallthumb.png"
                    ></img>
                  </div>
                </Link>

                {/* <div className="Casestudy-item-vertical small-card ">
                  <div className="Casestudy-item-content-vertical ">
                    <p className="Casestudy-item-cap">💰Navigation</p>
                    <h4 className="Casestudy-item-title">
                      Quick filtering for finding recently ran builds
                    </h4>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div>
            <img className="top-gradient" src={topgradient}></img>
          </div>
          <div className="Casestudy-container">
            <div className="contributions-content">
              <h5 className="Casestudy-container-title">
                Design System & Other Contributions{" "}
              </h5>
              {/* <div className="contributions-content">
                <h4 className="Casestudy-item-title">Design System</h4>
                <p className="Casestudy-item-description">
                  I am an active contributor of DesignStack. I have worked to
                  improve design system from creating components to finding new
                  patterns which solve complex usecases in scalabale and
                  repateabale way
                </p>
              </div>

              <img
                className="contributions-fullwidth"
                src={Designstack}
                alt=""
              /> */}
            </div>
            <div className="Casestudy-item gradient-bg">
              <div className="Casestudy-item-content">
                <h4 className="Casestudy-item-title">
                  Design Processes & Socialization
                </h4>
                <p className="Casestudy-item-description--small">
                  To streamline execution and standardize documentation, I
                  created <b>templates</b> that improved workflow efficiency.
                </p>
                <p className="Casestudy-item-description--small">
                  {" "}
                  Additionally, to showcase the impact of design team’s work, I
                  actively contributed to the Design Blog by -writing insightful
                  articles, designing and maintaining the blog.
                </p>
              </div>
              <img
                className="Casestudy-item-img"
                src="https://storage.googleapis.com/dv-portfolio-assets/components/othercontri.png"
              ></img>
            </div>
            <div className="row-work">
              <div>
                <Zoom>
                  <img
                    className=" contributions-fullwidth"
                    src="https://storage.googleapis.com/dv-portfolio-assets/components/Code%20editor.png"
                  ></img>{" "}
                </Zoom>
                <div className="contributions-content">
                  <h4 className="contributions-title">Code Editor</h4>
                </div>
              </div>
              <div>
                <Zoom>
                  <img
                    className=" contributions-fullwidth"
                    src="https://storage.googleapis.com/dv-portfolio-assets/components/Media%20player.png"
                  ></img>{" "}
                </Zoom>
                <div className="contributions-content ">
                  <h4 className="contributions-title">Media Player</h4>
                </div>
              </div>
            </div>
            <div className="row-work">
              <div>
                <Zoom>
                  <img
                    className=" contributions-fullwidth"
                    src="https://storage.googleapis.com/dv-portfolio-assets/components/Network%20logs.png"
                  ></img>
                </Zoom>
                <div className="contributions-content ">
                  <h4 className="contributions-title">Network Logs</h4>
                </div>
              </div>
              <div>
                <Zoom>
                  <img
                    className=" contributions-fullwidth"
                    src="https://storage.googleapis.com/dv-portfolio-assets/components/multiple.png"
                  ></img>{" "}
                </Zoom>
                <div className="contributions-content">
                  <h4 className="contributions-title">
                    Feature Card, Inline edit, Nested modals, +3
                  </h4>
                </div>
              </div>
            </div>

            {/* <h5 className="Casestudy-container-title">Other Contributions </h5>
            <div className="row-work">
              <div>
                <img
                  className=" contributions-fullwidth"
                  src={behindstack}
                ></img>
                <div className="contributions-content">
                  <h4 className="Casestudy-item-title">
                    Founding editorial team of Behind the Stack
                  </h4>
                  <p className="Casestudy-item-description">
                    DesignStack helps to socialse and share design related
                    activities internally in BrowserStack. I am the part of
                    editoriral team which helps to curate and publish articles,
                    manage wordpress blog and create and maintain the visual
                    design of the blog.
                  </p>
                </div>
              </div>
              <div>
                <img
                  className=" contributions-fullwidth"
                  src={behindstack}
                ></img>
                <div className="contributions-content">
                  <h4 className="contributions-title">
                    Design Processes & Documentation
                  </h4>
                  <p className="Casestudy-item-description">
                    DesignStack helps to socialse and share design related
                    activities internally in BrowserStack. I am the part of
                    editoriral team which helps to curate and publish articles,
                    manage wordpress blog and create and maintain the visual
                    design of the blog.
                  </p>
                </div>
              </div>
              <div>
                <img className=" contributions-fullwidth" src={git}></img>
                <div className="contributions-content ">
                  <h4 className="Casestudy-item-title">
                    Contributed to BrowserStack Docs
                  </h4>
                  <p className="Casestudy-item-description">
                    As one of the rare treats, while working to improving
                    onboarding of Automate product I contributed to BrowserStack
                    Docs in writing and improving content as well as writing
                    css, undertstanding Jekyl and maintaining widgets
                  </p>
                </div>
              </div>

              <div className="Casestudy-item ">
                <div className="Casestudy-item-content">
                  <h4 className="Casestudy-item-title">
                    Design Processes, Socialisation &
                  </h4>
                  <p className="Casestudy-item-description">
                    After the beta launch of Test Observability, I collaborated
                    with a newly joined Product Manager to design and implement
                    a Dashboards feature.
                  </p>
                </div>
                <img
                  className="Casestudy-item-img"
                  src="https://storage.googleapis.com/dv-portfolio-assets/casestudyimages/dashthumb.png"
                ></img>
              </div>
            </div> */}
          </div>
        </div>
        <Footer></Footer>
      </section>
    </>
  );
}

export default LoginPage;
