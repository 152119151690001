import React from "react";
import "./ImageCard.css";

import Zoom from "react-medium-image-zoom";

const ImageCard = ({ image }) => {
  return (
    <div className="ImageCard">
      <img src={image} />
    </div>
  );
};

export default ImageCard;
