import React, { useEffect, useState } from "react";
import "./Hero.css";
import TextSvg from "./assets/HeroText.svg";
import HeroAbout from "./assets/HeroAbout.png";
import Button from "../Button/Button";
import heroImages from "./assets/images";
import useInterval from "../../customHooks/useInterval/useInterval";
import { Link } from "react-router-dom";
import About from "../../pages/About/About";
import { MdArrowRight, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [maxImageLength, setMaxImageLength] = useState(
    Object.keys(heroImages).length
  );
  const [currentImage, setCurrentImage] = useState(heroImages[0]);
  const getImageWithIndex = (imageIndex) => {
    let targetImage = null;
    targetImage =
      heroImages[
        Object.keys(heroImages).find((image, index) => index == imageIndex)
      ];
    return targetImage;
  };

  function mailToo(address) {
    window.location.href = "mailto:" + address;
  }

  const handleImageIndexUpdate = () => {
    if (currentImageIndex < maxImageLength - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else {
      setCurrentImageIndex(0);
    }
  };

  useEffect(() => {
    setCurrentImage(getImageWithIndex(currentImageIndex));
  }, [currentImageIndex]);

  useInterval(() => {
    handleImageIndexUpdate();
  }, 500);

  return (
    <>
      <section className="Hero">
        <div className="Hero-content">
          <Link to="/about">
            <div className="Hero-contentImg">
              <img src={TextSvg} alt=" " className="rotating_text"></img>
              <img
                src="https://storage.googleapis.com/dv-portfolio-assets/hero/HeroAbout.png"
                alt=" "
                className="dev_image "
              ></img>
            </div>
          </Link>
          <div className="Hero-contentText">
            <h1 className="Hero-contentHeading"> Workfolio</h1>
            <div>
              <p className="Hero-contentPara">
                Hey there, I'm Devednya! I'm a self-taught digital product
                designer with over 7+ years of creative experience. My passion
                lies in crafting solutions for complex problems.
              </p>
              {/* <p className="Hero-contentPara">
                Currently I am working at BrowserStack - designing for
                Developers & building{" "}
                <a
                  className="Hero-externallink"
                  href="https://observability-demo.browserstack.com/projects/Test+Observability+Samples/builds"
                  target="blank"
                >
                  Test Observability <span> </span>
                </a>
              </p> */}
              <div className="Hero-contentCTA">
                <button onClick={() => navigate("/work")} className="Hero-link">
                  {" "}
                  <span className="Hero-contentCTAText"> View Work</span>
                  <MdKeyboardDoubleArrowRight />{" "}
                  <div className="marquee" aria-hidden="true">
                    <marquee className="marquee__inner">
                      <span className="marquee__text"> View Portfolio </span>
                      <span className="marquee__text"> 🔒 Work under NDA </span>
                      <span className="marquee__text"> View Portfolio </span>
                      <span className="marquee__text"> 🔒 Work under NDA </span>
                    </marquee>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Hero-slides">
          <img className="Hero-slidesImage" src={currentImage}></img>
        </div>
      </section>
    </>
  );
};

export default Hero;
