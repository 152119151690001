import { useEffect, useState } from "react";
import "./SectionNavigator.css";

const COLLAPSE_BREAKPOINT = 1440;

const SectionNavigator = () => {
  const [headings, setHeadings] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    window.innerWidth >= COLLAPSE_BREAKPOINT
  );

  useEffect(() => {
    const initializationTimeout = setTimeout(() => {
      const headingElements = document.querySelectorAll("h1, h2, h4");

      const headingData = Array.from(headingElements)
        .filter((heading) => heading.textContent.trim().length > 0)
        .map((heading, index) => ({
          id: heading.id || `section-${index}`,
          text: heading.textContent.trim(),
          level: parseInt(heading.tagName[1]),
          element: heading,
        }));

      headingData.forEach(({ id, element }) => {
        if (!element.id) {
          element.id = id;
        }
      });

      setHeadings(headingData);
      setIsInitialized(true);
    }, 1000);

    return () => clearTimeout(initializationTimeout);
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition > 0);
    };

    const handleResize = () => {
      const shouldExpand = window.innerWidth >= COLLAPSE_BREAKPOINT;
      setIsExpanded(shouldExpand);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial checks
    handleScroll();
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [isInitialized]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const getLevelClass = (level) => {
    return `section-navigator__item--level-${level}`;
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  if (!isInitialized || !isVisible || headings.length === 0) {
    return null;
  }

  return (
    <nav
      className={`section-navigator 
      ${isVisible ? "section-navigator--visible" : ""} 
      ${!isExpanded ? "section-navigator--collapsed" : ""}`}
    >
      <div className="section-navigator__header">
        <h3 className="section-navigator__title">
          {isExpanded ? "Page Contents" : "View Contents"}
        </h3>
        <button
          className="section-navigator__toggle"
          onClick={toggleExpanded}
          Contents
          aria-label={isExpanded ? "Collapse navigation" : "Expand navigation"}
          title={isExpanded ? "Collapse navigation" : "Expand navigation"}
        >
          {isExpanded ? "−" : "+"}
        </button>
      </div>
      <ul
        className={`section-navigator__list ${
          !isExpanded ? "section-navigator__list--hidden" : ""
        }`}
      >
        {headings.map(({ id, text, level }) => (
          <li
            key={id}
            className={`section-navigator__item ${getLevelClass(level)}`}
          >
            <button
              onClick={() => scrollToSection(id)}
              className="section-navigator__button"
            >
              {text}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SectionNavigator;
